import React from 'react';

import type { ClientId } from 'context/clientId';
import { TimeseriesSequenceQuery, useSequencesData } from 'hooks/useQueriedData';
import { addDataToScatterMap, ChartProps, color, dataSeriesFromScatterMap } from '.';
import { AxisOptions } from '../../../context/baselineConfig';
import ScatteredBaselineCard from './ScatteredBaselineCard';
import { ChartData } from 'chart.js';
import { ScatterDataPoint } from 'chart.js/auto';
import { useBaselineThresholds } from 'hooks/useBaseline';
import { useTargetUnits } from 'context/settings';
// import { useUnitSettings } from 'context/settings';

function useSfocOverLoad(clientId: ClientId, from: Date, to: Date, resolution: number) {
  const units = useTargetUnits();
  // TODO: enable dynamic units.
  //  this can be done when the baselines are converted to correct units as well
  //  until then, use defaultUnitSettings
  // const units = useUnitSettings();

  // Not needed right now, because we don't have alternative sfoc units
  //const units = defaultUnitSettings;

  // TODO: scale to unit
  const { data: baselineThresholds, error: baselineErr } = useBaselineThresholds(
    { clientId, familyKey: 'sfoc_iso' },
    {
      ySourceUnit: 'g/kWh',
      //yTargetUnit: // TODO: add target unit,
    }
  );
  const queries: TimeseriesSequenceQuery[] = [
    {
      module: 'calculated',
      objectId: 'total',
      objectIdNo: 0,
      pvId: 'load',
      pvIdNo: 0,
      clientId,
      resolution,
      from,
      to,
    },
    {
      module: 'calculated',
      objectIdNo: 0,
      objectId: 'total',
      pvId: 'focMassSpecificToPower',
      pvIdNo: 0,
      clientId,
      resolution,
      from,
      to,
      sourceUnit: 'g/kWh',
      targetUnit: units.massPerEnergyConsumption,
    },
  ];

  const [datas, loading, err] = useSequencesData(queries);

  const loadLabel = 'Load';
  const valueLabel = 'SFOC';

  const map = new Map();
  addDataToScatterMap(map, loadLabel, datas[0]?.map(({ x, y }) => ({ y: y * 100, x })) ?? []);
  addDataToScatterMap(map, valueLabel, datas[1] ?? []);

  const data: ChartData<any, ScatterDataPoint[], any> = {
    datasets: [
      {
        label: valueLabel,
        data: dataSeriesFromScatterMap(map, loadLabel, valueLabel),
        type: 'scatter',
        borderColor: color[0],
        borderWidth: 0.5, //todo define in theme
        fill: false,
        radius: 2, //todo define in theme
      },
    ],
  };

  // TODO: add to units
  const config: AxisOptions = {
    yMin: 150,
    yMax: 500,
    xMin: 1,
    xMax: 100,
    xLabel: 'Load [%]',
    yLabel: `SFOC [${units.massPerEnergyConsumption}]`,
  };

  return {
    data,
    loading,
    err,
    baselineThresholds,
    baselineErr,
    chartConfig: { axes: config },
  };
}

function SfocOverLoad({ clientId, from, to, resolution, aspectRatio, renderIndex = 0 }: ChartProps) {
  const { data, loading, err, chartConfig, baselineThresholds, baselineErr } = useSfocOverLoad(clientId, from, to, resolution);

  return (
    <ScatteredBaselineCard //todo merge back into scattered baseline card
      name="Specific Fuel Consumption"
      data={data}
      loading={loading}
      err={err}
      options={chartConfig?.axes}
      baselineGroup={baselineErr ? undefined : baselineThresholds}
      aspectRatio={aspectRatio}
      renderIndex={renderIndex}
    />
  );
}

export default SfocOverLoad;
